import React from 'react'
import Layout from '@/layouts/MainLayout'
import { ActivityPage } from '../components/ActivityPage/ActivityPage'
import SEO from '@/components/seo'
import { navigate } from 'gatsby'
import { useQuery } from 'react-query'
import { getEvent } from '../api'
import { EventPage } from '../components'

const Activity = ({ params }) => {
  const { data: events } = useQuery(
    ['event', params],
    async () => {
      const res = await getEvent({ type: 'event' }, params['*'])
      return res.data
    },
    {
      onError: () => navigate('/404'),
    }
  )

  return (
    <Layout>
      <SEO title={'Мероприятие'} />
      <ActivityPage data={events} EntityPage={EventPage} />
    </Layout>
  )
}

export default Activity
